.App {
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  background-color: #f0f0f0;
}

#video-container {
  text-align: center;
  margin-bottom: 20px;
}

#video {
  width: 100%;
  max-width: 600px;
}

.plans-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 80%;
  margin-top: 20px;
  gap: 50px;
}

.card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;
  width: 180px;
}

.card {
  width: 100%;
  padding: 20px;
  margin-bottom: 15px;
  background-color: #fff;
  border: 2px solid #ddd;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card:hover {
  background-color: #f0f8ff;
  border-color: #4CAF50;
}

.card.selected {
  background-color: #4CAF50;
  color: white;
  border-color: #4CAF50;
}

.card span {
  font-size: 24px;
  margin-bottom: 10px;
}

.plan-options, .duration-options, .summary {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
}

h2 {
  text-align: center;
}

#summary {
  font-size: 18px;
  text-align: center;
}

.separator-vertical {
  width: 2px;
  background-color: #ddd;
  height: 100%;
  margin: 0 20px;
}

#pay-btn {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
}

#pay-btn:hover {
  background-color: #45a049;
}

/* Адаптивность */
@media (max-width: 768px) {
  .plans-container {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  .separator-vertical {
    display: none;
  }

  .card-container {
    width: 100%;
    max-width: 300px;
  }

  #video {
    width: 100%;
    max-width: none;
  }

  #pay-btn {
    width: 100%;
    max-width: 300px;
  }
}

@media (max-width: 480px) {
  .plans-container {
    width: 95%;
  }

  h2 {
    font-size: 18px;
  }

  .card {
    padding: 15px;
    font-size: 14px;
  }

  #pay-btn {
    font-size: 14px;
    padding: 10px;
  }
}